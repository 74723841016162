class UrlList {
    firebaseUrl: string[];
    constructor() {
        this.firebaseUrl = [];
    }
}

const urlList = new UrlList();

urlList.firebaseUrl = [
    'app.pro-connect.jp',
    'pro-con-dev.firebaseapp.com',
    'pro-con-dev.web.app',
    'pro-con-stg.firebaseapp.com',
    'pro-con-stg.web.app'
];

export default urlList;
