import { useEffect, useState } from 'react';

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState<{
        width?: number;
        height?: number;
    }>({
        width: undefined,
        height: undefined
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
};

export const useMedia = (breakpoint = 768) => {
    const [isPC, setIsPC] = useState(false);

    const { width: windowWidth } = useWindowSize();

    useEffect(() => {
        if (!windowWidth) return;

        setIsPC(windowWidth >= breakpoint);
    }, [breakpoint, windowWidth]);

    return isPC;
};
