import Header from '@/components/ShortLP/Header';
import FirstView from '@/components/ShortLP/FirstView';
import Footer from '@/components/Footer';
import Head from '@/components/Head';
import useRedirectVercelApp from '@/hooks/useRedirectVercelApp';
import { Master } from '@/types/master';
import { usePageLoading } from '@/hooks/usePageLoading';
import Loading from '@/components/Loading';

export type Props = {
    atOfficeFrequencies: Master[];
};

export default function ShortLP({ atOfficeFrequencies }: Props) {
    useRedirectVercelApp();
    const { pageLoading } = usePageLoading();

    return (
        <>
            <Head />
            <Header />
            <main>
                {pageLoading ? <Loading /> : ''}
                <FirstView atOfficeFrequencies={atOfficeFrequencies} />
            </main>
            <Footer />
        </>
    );
}
