import ShortLP, { Props } from '@/components/ShortLP/Index';

import { GetStaticProps } from 'next';
import { getAtOfficeFrequencies } from '@/modules/master';

export const getStaticProps: GetStaticProps<Props> = async () => {
    // データの取得
    const [
        atOfficeFrequencies
    ] = await Promise.all([
        getAtOfficeFrequencies()
    ]);
    return {
        props: {
            atOfficeFrequencies,
        },
    };
};

export default ShortLP;
