import { Master } from '@/types/master';

export const omittedDescription = (
    text: string,
    count: number = 120
): string => {
    // `count`文字数以上は、「...」で省略
    if (text.length >= count) {
        const omittedDescription = text.substring(0, count);
        return `${omittedDescription}...`;
    }
    return text;
};

export const formatMasterListToString = (masterList: Master[]): string => {
    if (!masterList || masterList.length === 0) return '';

    return masterList.map((master) => master.text).join('、');
};

export const formatMasterToString = (master: Master | null) => {
    return master?.text || '';
};
