import React from 'react';
import classNames from 'classnames';
import Link from 'next/link';

type Props = {
    className?: string;
    href: string;
    useNextLink?: boolean;
    onClick?: () => void;
    children: React.ReactNode;
};

const AnchorLikeButton = ({
    className,
    href,
    useNextLink = false,
    onClick,
    children
}: Props) => {
    const anchorClass = classNames(
        'text-center font-bold hover:opacity-70 text-[12px] lg:text-[14px] xl:text-[16px]',
        className
    );
    const Anchor = (
        <a
            href={useNextLink ? undefined : href}
            className={anchorClass}
            onClick={onClick}
        >
            {children}
        </a>
    );

    return useNextLink ? (
        <Link href={href} passHref className={anchorClass} onClick={onClick}>
            {children}
        </Link>
    ) : (
        Anchor
    );
};

export default AnchorLikeButton;
