import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';

export const usePageLoading = () => {
    const router = useRouter();
    const [pageLoading, setPageLoading] = useState(false);
    useEffect(() => {
        const handleStart = (url: string) =>
            url !== router.asPath &&
            url.includes('search') &&
            setPageLoading(true);
        const handleComplete = () => setPageLoading(false);

        router.events.on('routeChangeStart', handleStart);
        router.events.on('routeChangeComplete', handleComplete);
        router.events.on('routeChangeError', handleComplete);

        return () => {
            router.events.off('routeChangeStart', handleStart);
            router.events.off('routeChangeComplete', handleComplete);
            router.events.off('routeChangeError', handleComplete);
        };
    }, []);
    return { pageLoading };
};
