import { Select as MantineSelect, SelectProps as MantineSelectProps, Text } from '@mantine/core';
import { CSSProperties } from 'react';
import typo from '@/styles/text.module.css';
import styles from './styles.module.css';
import { IconArrowDownBlue } from '@/components/Icon/IconArrowDownBlue';
import { Master } from '@/types/master';

export type SelectProps = Omit<MantineSelectProps, "data"> & {
    value: string;
    data: Master[];
    onChangeValue: (value: string) => void;
    label?: string;
    placeholder?: string;
    error?: string;
    variant?: string;
    onBlur?: () => void;
    style?: CSSProperties;
    className?: string;
};

export function Select({
    data,
    label,
    placeholder,
    value,
    error,
    variant = 'unstyled',
    onChangeValue,
    onBlur,
    style,
    className,
    ...props
}: SelectProps) {
    const handleChangeValue = (value: string | null) => {
        onChangeValue(data.find((e) => e.text === value)?.id.toString() || '');
    };

    return (
        <div className={className} style={{ ...(style || {}) }}>
            {label && (
                <Text className={`${typo.size_14_600} ${styles.label}`}>
                    {label}
                </Text>
            )}

            <MantineSelect
                variant={variant}
                checkIconPosition="right"
                data={data?.map((e) => e.text.toString())}
                value={
                    data?.find((e) => e.id === value?.toString())?.text ?? null
                }
                placeholder={placeholder}
                onChange={handleChangeValue}
                onBlur={onBlur}
                radius={4}
                rightSection={<IconArrowDownBlue width={9} height={12} />}
                className={styles.select}
                {...props}
            />

            {error && (
                <Text className={`${typo.size_12_300} ${styles.error}`}>
                    {error}
                </Text>
            )}
        </div>
    );
}
