import React from 'react';
import CircularProgress, {
    CircularProgressProps
} from '@mui/material/CircularProgress';

const Loading: React.FC<CircularProgressProps> = (props) => {
    const { ...rest } = props;
    return (
        <>
            <div className="fixed inset-0 bg-[#000] opacity-[45%] z-50"></div>
            <CircularProgress
                {...rest}
                className="fixed inset-0 flex mx-auto my-auto justify-center z-50"
            />
        </>
    );
};

export default Loading;
