import { Master } from '@/types/master';
export const CONSTRUCTOR_TIER_LEVEL = {
    PRIME: { id: '1', text: '元請け' },
    OWN: { id: '5', text: '自社案件' }
} as const;

export const AT_OFFICE_FREQUENCY = {
    REMOTE_WORK: '10'
};

// atOfficeFrequencies
export const othersAtOfficeFrequency: Master = {
    id: '99',
    text: 'その他'
};

// 案件検索の並び順
export const projectSearchOrderByMaster = [
    { id: 'new', text: '新着順' },
    { id: 'monthlyRate', text: '高単価順' }
];
