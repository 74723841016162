import Image from 'next/image';
import AnchorLikeButton from '@/components/Header/anchorLikeButton';
import { useUrlContext } from '@/provider/urlContext';
import { recordOnClickSignUp } from '@/modules/log';
import { ProjectSearch } from '@/components/ShortLP/FirstView/ProjectSearch';
import { ProjectsFilterForm, ProjectsFilterFormForFV } from '@/types/project';
import { useForm } from '@mantine/form';
import { initialProjectSearchValues } from '@/modules/config/project';
import { Master } from '@/types/master';
import { useRouter } from 'next/router';
import { useMedia } from '@/hooks/useMedia';
import { othersAtOfficeFrequency } from '@/constants/master';

const imagePCFv01 =
    '/images/ProConnect_design2407/PC/image_fv01/image_fv01_4x.webp';

const imageSPFv01 =
    '/images/ProConnect_design2407/SP/image_sp_fv01/image_fv01_4x.webp';

export type FirstViewProps = {
    atOfficeFrequencies: Master[];
};

function FirstView({ atOfficeFrequencies }: FirstViewProps) {
    const router = useRouter();
    const isPC = useMedia();

    const domain = process.env.NEXT_PUBLIC_PRO_CONNECT_APP_FL_DOMAIN;
    const { signUpUrl, query } = useUrlContext();
    const signUpUrlWithDomain = `https://${domain}${signUpUrl}`;

    const filteredAtOfficeFrequencies = atOfficeFrequencies.filter(
        (atOfficeFrequency) =>
            atOfficeFrequency.id !== othersAtOfficeFrequency.id
    );

    const { values, setFieldValue } = useForm<ProjectsFilterFormForFV>({
        initialValues: {
            text: initialProjectSearchValues.text,
            atOfficeFrequency: initialProjectSearchValues.atOfficeFrequency,
            lowerLimitMonthlyRate:
                initialProjectSearchValues.lowerLimitMonthlyRate
        }
    });

    const handleOnChange = (
        field: keyof ProjectsFilterForm,
        value: string | number | string[]
    ) => {
        setFieldValue(field, value);
    };

    const onFilter = () => {
        const convertedQueryValues = {
            ...router.query,
            ...values,
            lowerLimitMonthlyRate:
                values.lowerLimitMonthlyRate?.toString() || '',
            page: '1',
            orderBy: initialProjectSearchValues.orderBy
        };

        const searchParams = new URLSearchParams(convertedQueryValues);
        router.push({
            pathname: 'project/search',
            query: searchParams.toString()
        });
    };

    return (
        <>
            <div className="flex flex-col md:flex-row md:h-full">
                <div className="relative w-full">
                    <Image
                        src={imagePCFv01}
                        alt=""
                        fill
                        className="md:block hidden object-[right_calc(20%)_top_calc(0%)] z-[-1] object-cover"
                    />
                    <Image
                        src={imageSPFv01}
                        alt=""
                        fill
                        className="md:hidden block object-[right_calc(20%)_top_calc(0%)] z-[-1] object-cover"
                    />
                    <div className="py-[40px] md:py-[60px] px-[12px] lg:px-[70px] xl:px-[120px]">
                        <p className="font-hiragino-sans font-bold text-[#182744] text-[36px] sm:text-[38px] md:text-[56px] xl:text-[70px] leading-[60px] sm:leading-[70px] md:leading-[80px] xl:leading-[90px] mb-[30px] md:mb-[40px] mx-[16px]">
                            <span className="md:hidden block">
                                フリー
                                <br />
                                コンサルの
                                <br />
                                ための案件
                                <br />
                                紹介サービス
                            </span>
                            <span className="md:block hidden">
                                フリーランス
                                <br />
                                コンサルタントのための
                                <br />
                                案件紹介サービス
                            </span>
                        </p>
                        <div className="flex md:flex-row flex-col items-center sm:justify-start justify-center mt-[80px] md:m-0">
                            <AnchorLikeButton
                                href={signUpUrlWithDomain}
                                onClick={() => recordOnClickSignUp()}
                                className="flex items-center justify-center rounded-[4px] max-w-[366px] w-full h-[64px] leading-[26px] bg-gradient-to-r from-[#0262BD] to-[#0A9BE1]"
                            >
                                <p className="font-hiragino-sans text-white text-center md:text-[20px] text-[18px] mb-[2px]">
                                    <span className="text-[15px]">
                                        非公開案件多数！
                                    </span>
                                    <br />
                                    簡単1分登録で案件紹介を受ける
                                </p>
                            </AnchorLikeButton>
                            <AnchorLikeButton
                                href={`/project${query}`}
                                className="flex items-center justify-center rounded-[4px] max-w-[366px] w-full h-[64px] leading-[26px] bg-white border border-solid border-[#1D55AE] md:ml-[20px] mt-[12px] md:mt-0"
                            >
                                <p className="font-hiragino-sans text-[#1D55AE] text-center text-[20px]">
                                    まずは案件を検索する
                                </p>
                            </AnchorLikeButton>
                        </div>
                        {isPC && (
                            <ProjectSearch
                                values={values}
                                atOfficeFrequencies={
                                    filteredAtOfficeFrequencies
                                }
                                onChangeValue={handleOnChange}
                                onFilter={onFilter}
                            />
                        )}
                    </div>
                </div>
            </div>
            {!isPC && (
                <ProjectSearch
                    values={values}
                    atOfficeFrequencies={filteredAtOfficeFrequencies}
                    onChangeValue={handleOnChange}
                    onFilter={onFilter}
                />
            )}
        </>
    );
}

export default FirstView;
