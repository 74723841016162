import Image from 'next/image';

type IconArrowDownBlueProps = {
    width: number;
    height: number;
};

export function IconArrowDownBlue({ width, height }: IconArrowDownBlueProps) {
    return (
        <Image
            src={'/images/icon_arrow_down_blue.svg'}
            alt="Icon"
            width={width}
            height={height}
            priority
            loading="eager"
        />
    );
}
