import classNames from 'classnames';

type SpacerProps = {
    size: number | string;
    horizontal?: boolean;
    className?: string;
};

const isNumber = (value: any) => {
    return Number.isFinite(value);
};

function Spacer({ size, horizontal, className }: SpacerProps) {
    const style = (() => {
        if (isNumber(size)) {
            if (horizontal) {
                return { width: Number(size) * 4 };
            } else return { height: Number(size) * 4 };
        } else {
            if (horizontal) {
                return { width: size };
            } else return { height: size };
        }
    })();
    return (
        <div
            className={classNames(
                { 'h-auto inline-block shrink-0': horizontal },
                { 'w-auto shrink-0': !horizontal },
                className
            )}
            style={style}
        />
    );
}

export default Spacer;
