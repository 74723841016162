import { Master } from "@/types/master";

export const lowerLimitList: Master[] = [{
    id: '80',
    text: '80万円〜'
}, {
    id: '100',
    text: '100万円〜'
}, {
    id: '120',
    text: '120万円〜'
}, {
    id: '150',
    text: '150万円〜'
}, {
    id: '180',
    text: '180万円〜'
}, {
    id: '200',
    text: '200万円〜'
}]