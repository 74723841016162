import { TextInput as MantineTextInput, TextInputProps as MantineTextInputProps, Text } from "@mantine/core";
import { CSSProperties } from "react";
import typo from "@/styles/text.module.css";
import styles from "./index.module.css";
import { z } from "zod";

type TextInputTypesProps = Record<
  TextInputType,
  {
    variant: string;
    textInputClassName: string;
  }
>;

const TEXT_INPUT_TYPES: TextInputTypesProps = {
  DEFAULT: {
    variant: "default",
    textInputClassName: "",
  },
  UNSTYLED: {
    variant: "unstyled",
    textInputClassName: styles.textInput,
  },
};

const TextInputTypeList: [string, ...string[]] = ["DEFAULT", "UNSTYLED"];
const textInputTypeSchema = z.enum(TextInputTypeList);
export type TextInputType = z.infer<typeof textInputTypeSchema>;

interface TextInputProps extends MantineTextInputProps {
  textInputType?: TextInputType;
  onChangeValue: (value: string) => void;
  onBlur?: () => void;
  style?: CSSProperties;
}

export function TextInput({
  textInputType = "DEFAULT",
  label,
  error,
  onChangeValue,
  onBlur,
  style,
  className,
  ...props
}: TextInputProps) {
  const { variant, textInputClassName } = TEXT_INPUT_TYPES[textInputType];

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeValue(event.currentTarget.value);
  };

  return (
    <div className={className} style={{ ...(style || {}) }}>
      {label && <Text className={`${typo.size_14_600} ${styles.label}`}>{label}</Text>}
      <MantineTextInput
        variant={variant}
        onChange={onChange}
        onBlur={onBlur}
        radius={4}
        className={textInputClassName}
        {...props}
      />

      {error && <Text className={`${typo.size_12_300} ${styles.error}`}>{error}</Text>}
    </div>
  );
}
