import { useEffect } from 'react';
import urlList from '../modules/urlList';

const useRedirectVercelApp = () => {
    useEffect(() => {
        const currentDomain = window.location.hostname;
        const pathName = window.location.pathname;
        const isFirebaseApp = urlList.firebaseUrl.includes(currentDomain);
        const rootDomain = process.env.NEXT_PUBLIC_ROOT_URL;
        if (isFirebaseApp) {
            const query = window.location.search;
            window.location.href = query
                ? `${rootDomain}${pathName}${query}`
                : `${rootDomain}${pathName}`;
        }
    }, []);
};

export default useRedirectVercelApp;
